body, html, #root, .App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background-color: #0099cc;
    color: #ffffff;
}

.mainContainer {
    margin: auto;
    width: 1024px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.headerImage {
    width: 100%;

    webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

.tile {
    width: 100%;
    padding: 15px;
    text-align: left; 
}

.tileHeader {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.tile ul {
    margin: 15px;
    list-style-type: none;
}

.tile ul li  {
    margin: 5px;
    margin-top: 15px;
}

.liHeader {
    font-weight: 700;
    font-size: 16px;
}
.liContent {
    padding: 5px;
}

.liContentLine {
    margin-top: 5px;
}

.liImages {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.liImages a {
    display: contents;
}
.liImages img {
    width: 30%;
    border-radius: 10px;
}

@media (max-width: 1024px) {
    .mainContainer {
        width: 100%;
    }

    .tileHeader {
        font-size: 18px;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}